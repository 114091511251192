import { AppRouter } from "./router/AppRouter";
import { AppProvider, AppTitleMenu, } from './context/AppProvider';
import { AppAuthProvider } from "./context/AppAuthProvider";

const App = () => {
  return (
    <AppProvider>
      <AppAuthProvider>
        <AppTitleMenu>          
          <AppRouter />
        </AppTitleMenu>
      </AppAuthProvider>
    </AppProvider>
  )
}

export default App
