import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSinToken } from '../../../helpers/fetch';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
// import { DropdownItem } from "reactstrap";
import '../../../assets/scss/_login.scss';
import { AppAuthContext } from '../../../context/AppAuthContext';
import { Button } from 'primereact/button';
// import SiteSeal from './SiteSeal';

// import dotenv from 'dotenv';
// dotenv.config();

const Login = () => {
    // console.log('Login');    
    // const VL_APP_NAME: any = process.env.APP_NAME;
    // const VL_APP_VERSION: any = process.env.APP_VERSION;

    const VL_APP_NAME: any = 'App';
    const VL_APP_VERSION: any = '2.17.2';

    const [vl_username, setUsername] = useState('');
    const [vl_password, setPassword] = useState<any>('');
    const [vl_action, setAction] = useState<any>('V');
    const [vl_IdSucursal, setIdSucursal] = useState<any>(0);
    const [vl_dataSucursal, setDataSucursal] = useState<any>([]);
    const messageRef = useRef<any>(null);
    const sha1 = require('js-sha1');
    const { login } = useContext(AppAuthContext);
    const navigate = useNavigate();
    // const isSiteSealMounted = useRef(false);

    const handleValidaUser = async (e: any) => {
        e.preventDefault();
        const param = {
            vp_action: 'S',
            vp_username: vl_username,
            vp_password: '',
            vp_IdSucursal: 0
        }

        if (vl_username === '') return;
        const response = await fetchSinToken('auth/pa_authentication_username', param, 'POST');
        const datajson = await response.json();
        // console.log(datajson);
        if (datajson.data[0].AuhtUser === 1) {
            // console.log(datajson.data[0].AuhtUserData.IdSucursal);
            // console.log(datajson.data[0].AuhtUserData.IdSucursal.IdSucursales.split(','));
            let array = datajson.data[0].AuhtUserData.IdSucursal.IdSucursales.split(',');
            let arrayKey: any = [];
            array.forEach(
                function (row: any) {
                    // console.log(row)
                    arrayKey.push({
                        'IdSucursal': row.split('|')[0],
                        'DescripSucursal': row.split('|')[1],
                    });
                }
            );
            // console.log(arrayKey);
            setDataSucursal(arrayKey);
        } else {

            setDataSucursal([]);
            const message = datajson.data[0].AuhtMessage;
            messageRef.current?.show({
                closable: false,
                severity: 'error',
                //summary: 'Message',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });

        }
    }

    const handleLogin = async (e: any) => {
        e.preventDefault();
        // console.log(username);   
        // console.log(sha1(password));
        setAction('V');

        const param = {
            vp_action: vl_action,
            vp_username: vl_username,
            vp_password: sha1(vl_password),
            vp_IdSucursal: vl_IdSucursal
        }
        // console.log(param);
        if (String(vl_username).trim() === '') {
            const message = 'Ingresar el usuario';
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
            return;
        }
        if (String(sha1(vl_password)).trim().length === 0) {
            const message = 'Ingresar contraseña de usuario';
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
            return;
        }
        if (vl_IdSucursal === 0) {
            const message = 'Selecione una oficina';
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
            return;
        }
        const response = await fetchSinToken('auth/pa_authentication_login', param, 'POST');
        const datajson = await response.json();
        // console.log(datajson);
        if (datajson.data[0].AuhtUser === 1) {
            localStorage.setItem('auhtUser', datajson.data[0].AuhtUser);
            localStorage.setItem('authIdUsuario', datajson.data[0].AuhtUserData.IdUsuario);
            localStorage.setItem('authUserName', datajson.data[0].AuhtUserData.UserName);
            localStorage.setItem('authUserFullName', datajson.data[0].AuhtUserData.UserFullName);
            localStorage.setItem('authUserCargo', datajson.data[0].AuhtUserData.UserCargo);
            localStorage.setItem('authEmail', datajson.data[0].AuhtUserData.Email);
            localStorage.setItem('authIdSucursal', datajson.data[0].AuhtUserData.IdSucursal.IdSucursales.split('|')[0]);
            localStorage.setItem('authDescripcionSucursal', datajson.data[0].AuhtUserData.IdSucursal.IdSucursales.split('|')[1]);
            localStorage.setItem('authIdStaff', datajson.data[0].AuhtUserData.IdStaff);
            localStorage.setItem('authIdPerfil', datajson.data[0].AuhtUserData.IdPerfil);
            localStorage.setItem('authData', JSON.stringify(datajson.data[0]));
            localStorage.setItem('token', datajson.token);
            localStorage.setItem('mainNotify', 'Y');
            // props.setIsLogin(true);
            login(true);

            //Controla usuarios con clave expirado, obliga cambiar
            if (datajson.data[0].AuhtCodigo === 2) {
                const IdUsuario = datajson.data[0].AuhtUserData.IdUsuario;
                const path = '/account/expirepassword/' + IdUsuario;
                navigate(path);
            } else {
                const lastPath = localStorage.getItem('lastPath') || '/AccountHome';
                navigate(lastPath, {
                    replace: true
                });
            }

        } else {
            const message = datajson.data[0].AuhtMessage;
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
        }

    }

    const onChangeIdSucursal = (e: any) => {
        setIdSucursal(e.value);
    }
    const onResetPassword = () => {
        const path = '/account/authSendResetPassword/';
        navigate(path);
    }

    useEffect(() => {
        console.log('xxxxx')

        // if (document.getElementById('siteseal'))
        //     document.getElementById('siteseal')?.remove();

        const script = document.createElement('script');
        script.src = "https://seal.godaddy.com/getSeal?sealID=6RtJCvhtmmjxexbT0m1DwVNFNx7RF8EfG1h3EJWz6iiHhMgkDFrpWbVNqflL";
        script.async = true;
        document.getElementById('siteseal')?.appendChild(script);
        // eslint-disable-next-line react-hooks/exhaustive-deps        
      }, []);

    return (
        <>
            <div className="wrapper d-flex flex-column min-vh-100 bg-gradient-indigo text-white">
                <section className="border-round-bottom">
                    <div className="container pt-2">
                        <nav className="navbar navbar-expand-lg">
                            <img className="navbar-logo navbar-logo-light" src="../logo-sys.png" alt="Logo"></img>
                            <span className='text-sm font-base' >{VL_APP_NAME + ' v' + VL_APP_VERSION} </span>
                        </nav>
                    </div>                    
                </section>
                <main className="form-signin">
                    <form onSubmit={handleLogin}  >
                        <div className="col mb-3 text-white  w-100  ">
                            <div className='w-100' >
                                <img src="../apple-touch-icon.png" alt="" />
                            </div>
                        </div>
                        <div className="p-field ml-2 p-grid mb-1">
                            <div className="p-col mb-0">
                                <label className="text-sm text-xl" style={{ textAlign: 'start' }}>
                                    <h1 className="mb-0 mt-0 text-lg">Iniciar sesión</h1>
                                </label>
                            </div>
                        </div>
                        <i className="bi bi-unlock-fill"></i>

                        <div className="form-floating">
                            <input type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Usuario"
                                autoFocus
                                value={vl_username}
                                onChange={(e) => setUsername(String(e.currentTarget.value).toUpperCase())}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        document.getElementById("floatingPassword")?.focus();
                                        e.preventDefault();
                                    }
                                }}
                                onBlur={(e: any) => {
                                    handleValidaUser(e);
                                    document.getElementById("floatingPassword")?.focus();
                                }}
                            />
                            <label className='text-black-50' htmlFor="floatingInput" >Usuario</label>

                        </div>

                        <div className="form-floating mt-1">
                            <input type="password"
                                className="form-control"
                                id="floatingPassword"
                                placeholder="Password"
                                value={vl_password}
                                onChange={(e) => setPassword(String(e.currentTarget.value))} />
                            <label className='text-black-50' htmlFor="floatingPassword"  >Password</label>
                        </div>
                        <div className="form-floating">
                            <Dropdown className='w-100'
                                id="floatingIdSucursal"
                                value={vl_IdSucursal}
                                options={vl_dataSucursal}
                                onChange={onChangeIdSucursal}
                                optionValue="IdSucursal"
                                optionLabel="DescripSucursal"
                                placeholder='Oficina'
                            />                            
                        </div>
                        <div className="pt-3">
                            <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                        </div>
                        <div className="pt-4 flex flex-grid justify-content-end ">
                            <Button className='p-button-link text-sm font-base text-gray-600' label='¿Olvidaste tu contraseña?'
                                onClick={() => {
                                    onResetPassword();
                                }}
                            >
                            </Button>
                        </div>
                    </form>
                    <Messages ref={messageRef} />

                </main>

                <div className="separator-top">                    
                    <div className="container py-5">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-5 text-center text-md-left">
                            </div>
                            <div className="text-center">
                                <ul className="socials">
                                    <span className='text-sm font-base' >PSISABE - Centro Psicológico  &copy; { new Date().getFullYear()  } </span>
                                    <li className="pi pi-twitter pl-1"></li>
                                    <li className="pi pi-instagram pl-1"></li>
                                    <li className="pi pi-facebook pl-2"></li>
                                </ul>                                                                
                            </div>
                            <div className="col-md-5 text-center text-md-right"> 
                            <span id="siteseal"></span>                                                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}
export default Login;

