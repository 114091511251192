import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { AppAuthContext } from '../context/AppAuthContext';
// import { fetchConToken } from '../helpers/fetch';
import AuthPasswordExpire from '../views/components/auth/AuthPasswordExpire';
import AuthResetPassword from '../views/components/auth/AuthResetPassword';
import AuthResetPasswordExpire from '../views/components/auth/AuthResetPasswordExpire';
import AuthSendResetPassword from '../views/components/auth/authSendResetPassword';
import Login from '../views/containers/login/Login';
import AppDashboardRoutes from './AppDashboardRoutes';
import { useIdleTimer, workerTimers } from 'react-idle-timer';

export const AppRouter = () => {

  const timeout = 1000 * 60 * 45 // 45 minuto en milisegundos
  // const timeout = 1000 * 60 // 1 minuto en milisegundos
  // const promptTimeout = 1000 * 45 // 45 segundos para continuar la session
  const promptBeforeIdle = 1000 * 45
  const [remaining, setRemaining] = useState(0)
  // const [isIdle, setIsIdle] = useState(false)
  // const handleOnActive = () => setIsIdle(false)
  // const handleOnIdle = () => setIsIdle(true)

  const onPrompt = () => {
    console.log(`onPrompt`);
    setConfirmDialog(true);
    // setRemaining(promptTimeout)
    setRemaining(promptBeforeIdle)
    
  }

  const onIdle = () => {
    console.log(`onIdle`);
    // setOpen(false)
    setConfirmDialog(false);
    setRemaining(0);
    handleLogout();
  }

  const onActive = () => {
    console.log(`onActive`);
    setConfirmDialog(false);
    setRemaining(0);
  }

  const onContinueClick = (e: React.FormEvent) => {
    console.log(`onContinueClick...`);
    e.preventDefault();
    setConfirmDialog(false);
    activate()
  }

  const onExitSessionClick = (e: React.FormEvent) => {
    // console.log(`onExitSessionClick...`);
    setConfirmDialog(false);
    handleLogout();
  }

  const {
    getRemainingTime, isPrompted, activate
  }: any = useIdleTimer({
    timers: workerTimers,
    timeout,
    // promptTimeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    disabled: window.localStorage.getItem('token')?.length ? false : true
  });


  const { login, authData, logout } = useContext(AppAuthContext);
  // const lastPath = localStorage.getItem('lastPath') || '/';
  const lastPath = '/AccountHome';
  const [vl_confirmDialog, setConfirmDialog] = useState<boolean>(false);

  //otro metodo para verificar con token en el servidor (tambien funciona)
  // const pa_authentication_verifyToken = async () => {
  //   // 45min de session 
  //   const response = await fetchConToken('auth/pa_authentication_verifyToken', {}, 'POST');
  //   const resp = await response.json();
  //   // console.log(resp);
  //   if (resp.data.isExpired) {
  //     setConfirmDialog(true);
  //   }
  // }

  const handleLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('auhtUser');
    window.localStorage.removeItem('authIdUsuario');
    logout(false);
    <Navigate to={'/login'} />
  }


  useEffect(() => {
    //verificar token (ACTIVAR EN PRODUCCION)
    // pa_authentication_verifyToken();
    const JWTAuthentication: any = window.localStorage.getItem('token')?.length;
    // console.log(JWTAuthentication);
    if (JWTAuthentication > 0) {
      console.log('is Autenticate!');
      login(true); // isAutenticate ? YES        
    }

    //contador para la ventana ...
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps              
  }, [getRemainingTime, isPrompted]);

  const footerPopup = () => {
    return (
      <div className='flex justify-center bg-gray-50 w-full p-3'>
        <Button label="Continue" className='btn btn-lg btn-primary' icon="pi pi-sign-in" onClick={onContinueClick} />
        <Button label="Exit" className='btn btn-lg p-button-secondary' icon="pi pi-power-off" onClick={onExitSessionClick} />
      </div>
    )
  }

  return (
    <>

      <Dialog
        closable={false}
        visible={vl_confirmDialog}
        // visible={true}
        footer={footerPopup()}
        style={{ width: '30vw' }}
        onHide={() => setConfirmDialog(false)}
        header="System security"
        headerClassName='flex justify-center bg-gray-100 text-primary'
      >
        <>          
            <div className="flex w-full items-center justify-center border-b border-zinc-400 pt-2">
              <div className="flex items-center">
                <div className="text-lg font-bold text-slate-700 uppercase">Your session is about to expire.</div>
              </div>
            </div>
            <div className='grid gap-2 ml-1 mt-3'>
              <div className="col-0 flex">
                <div className="text-lg text-slate-700 white-space-normal">Click continue to extend your time or exit to return to the home page.</div>
              </div>
              <div className="col-auto flex items-center justify-center mt-3">
                <p className="text-lg font-semibold text-slate-700 text-center">You have {remaining} seconds to continue session.</p>
              </div>
            </div>
          

        </>
      </Dialog>

      {/* <Dialog visible={vl_confirmDialog} onHide={() => setConfirmDialog(false)} closable={false}
        header="PSISABE: System security" 
      >
        <div className="container" >
          <div className="flex flex-column card-container">
            <h5>The user session has expired.</h5>
            
            <div className='flex align-items-center justify-content-center h-4rem' >            
              <Button label="Aceptar" icon="pi pi-lock-open"
                onClick={() => {
                  handleLogout()
                  setConfirmDialog(false);
                }}
                className="p-button-sm p-button-primary" />
            </div>
          </div>
        </div>        
      </Dialog> */}

      <BrowserRouter>
        <Routes>
          <Route path="/login" element={authData.logged ? <Navigate to={lastPath} /> : <Login />} />
          <Route path="/*" element={authData.logged ? <AppDashboardRoutes /> : <Navigate to="/login" />} />
          <Route path="/account/expirepassword/:id" element={authData.logged ? <AuthPasswordExpire /> : <Navigate to="/login" />} />
          <Route path="/account/authSendResetPassword" element={<AuthSendResetPassword />} />
          <Route path="/account/authResetPassword/:id/:token" element={<AuthResetPassword />} />
          <Route path="/account/authResetPasswordExpire" element={<AuthResetPasswordExpire />} />
        </Routes>
      </BrowserRouter>
    </>

  );
}