import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { fetchSinToken } from '../../../../helpers/fetch';
import { setIntlDateTimeFormat, setIntlTimeFormat } from '../../../../helpers/functions';

// Componentes del formulario
import { Filter } from './Filter';
import { DatoPaciente } from './DatoPaciente';
// import { OrdenPagos } from './OrdenPagos';
import { DerivacionPacienteForm } from '../derivacionPaciente/DerivacionPacienteForm';
import useModal from '../../../../context/WindowsOpen';
import { FooterGridQuantity } from '../../util/FooterGrid';
import BuscadorClientes from '../../util/BuscadorClientes';
import TitleForm from '../../util/TitleForm';
// import { FilterMatchMode, FilterOperator } from 'primereact/api';


export const OrdenAtencionForm = ({ ...props }) => {

    //valores INPUT Cliente/Paciente
    const [vl_IdCliente, setInputValIdCliente] = useState<any>('0');
    const [vl_Nombre, setInputValNombre] = useState('');
    const [vl_ApPaterno, setInputValApPaterno] = useState('');
    const [vl_ApMaterno, setInputValApMaterno] = useState('');
    const [vl_IdTipoDocumento, setInputValIdTipoDocumento] = useState<any>('');
    const [vl_NumeroDocumento, setInputValNumeroDocumento] = useState('');
    const [vl_Edad, setInputValEdad] = useState<number>(0);
    const [vl_Sexo, setInputValSexo] = useState<any>('');

    // Inputs Crud 
    const [vl_ACTION, setInputValACTION] = useState('I');
    const [vl_IdRegistro, setInputValIdRegistro] = useState('0');
    const [vl_IdOrdenAtencion, setInputValIdOrdenAtencion] = useState('0');
    const [vl_FechaAtencion, setInputValFechaAtencion] = useState<any>(new Date());
    const [vl_HoraAtencion, setInputValHoraAtencion] = useState<any>(new Date());
    const [vl_NumeroOrden, setInputValNumeroOrden] = useState('');
    const [vl_IdHistoriaClinica, setInputValIdHistoriaClinica] = useState('0');
    const [vl_NumeroHistoriaClinica, setInputValNumeroHistoriaClinica] = useState('');
    // const [vl_Pagado, setInputValPagado] = useState<boolean>(false);
    // const [vl_MontoPagado, setInputValMontoPagado] = useState<any>('0.00');
    // const [vl_IdFormaPago, setInputValIdFormaPago] = useState<any>('');
    // const [vl_ReferenciaPago, setInputValReferenciaPago] = useState<any>('');
    const [vl_Observaciones, setInputValObservaciones] = useState<any>('');
    const [vl_IdEspecialidad, setInputValIdEspecialidad] = useState(1);
    const [vl_IdStaff, setInputValIdStaff] = useState(1);
    const [vl_Validez, setInputValValidez] = useState<boolean>(false);
    const [vl_Dias, setInputValDias] = useState('0');
    // const [vl_TotalInversion, setInputValTotalInversion] = useState<number>(0);
    // const [vl_Cuota, setInputValCuota] = useState<boolean>(false);
    // const [vl_NumeroCuotas, setInputValNumeroCuotas] = useState<number>(0);
    // const [vl_ImporteCuotas, setInputValImporteCuotas] = useState<number>(0);
    // const [vl_ImporteSaldo, setInputValImporteSaldo] = useState<number>(0);
    // const [vl_MontoTotalSel, setInputValMontoTotalSel] = useState<number>(0);
    const [vl_CantidadTotalSel, setInputValCantidadTotalSel] = useState<any>(0);
    // const [vl_TipoPago, setInputValTipoPago] = useState('M');

    const [vl_NumeroSesiones, setNumeroSesiones] = useState('0');
    const [vl_FrecuenciaAtencion, setFrecuenciaAtencion] = useState<any>('');

    const [dataEspecialidad, setDataEspecialidad] = useState([]);
    const [dataIdStaff, setDataIdStaff] = useState([]);
    const [dataCodigoServicios, setDataCodigoServicio] = useState<any>([]);

    const [dataTipoAtencion, setDataTipoAtencion] = useState<any>([]);
    const [IdTipoAtencion, setIdTipoAtencion] = useState<any>('');

    // const [dataIdFormaPago, setIdDataFormaPago] = useState([]);
    // const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
    // const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);

    const [selectedIemsGrid, setSelectedIemsGrid] = useState<any>([]);
    const [editingRows, setEditingRows] = useState<any>([]);

    const [activeIndex, setActiveIndex] = useState(0);
    const [chkValEvaluacion, setChkValEvaluacion] = useState<boolean>(false);
    const [chkValInterConsulta, setChkValInterConsulta] = useState<boolean>(false);
    const [chkValInformePsi, setChkValInformePsi] = useState<boolean>(false);

    //Visible
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);

    const [b_hiddenGrabar, setHiddenbtnGrabar] = useState<boolean>(false);
    // const [b_hiddenEdit, setHiddenbtnEdit] = useState<boolean>(false);
    const [b_hiddenAnular, setHiddenbtnAnular] = useState<boolean>(true);

    const [deleteItemDialog, setDeleteItemDialog] = useState<boolean>(false);

    //OverlayPanel
    // const [source, setSource] = useState<any>([]);
    const [target, setTarget] = useState<any>([]);

    //Messages
    const toast = useRef<any>(null);
    const toastConfirm = useRef<any>(null);

    const op = useRef<OverlayPanel>(null);
    // const oplanPago = useRef<OverlayPanel>(null);

    // Input ref        
    // const inputMontoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputNumeroCuotasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputImporteCuotasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputReferenciaPagoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputObservacionesRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    //
    const inputNumeroHistoriaClinicaRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputDiasRef = useRef<HTMLElement | HTMLInputElement | any>(null);

    //disabled INPUTs
    const [disabledNumeroHistoriaClinica, setDisabledNumeroHistoriaClinica] = useState(false);

    // const [disabledNumeroDocumento, setDisabledNumeroDocumento] = useState(false);
    // const [disabledMonto, setDisabledMonto] = useState(false);
    // const [disabledIdFormaPago, setDisabledIdFormaPago] = useState(false);
    // const [disabledReferenciaPago, setDisabledReferenciaPago] = useState(false);
    //
    // const [disabledNumeroCuotas, setDisabledNumeroCuotas] = useState(false);
    // const [disabledImporteCuotas, setDisabledImporteCuotas] = useState(false);
    const [disabledDias, setDisabledDias] = useState(false);
    // const [disabledbtnPlanPago, setDisabledbtnPlanPago] = useState(true);

    const isMountedFormDeriv = useRef(false);
    const [isOpenModal01, openModal01, closeModal01] = useModal();
    const [dataCliente, setDataCliente] = useState('');


    // set Values
    const onIdEspecialidadChange = (e: { value: any }) => {
        setInputValIdEspecialidad(e.value);
    }
    // const onIdFormaPagoChange = (e: { value: any }) => {
    //     setInputValIdFormaPago(e.value);
    // }
    const onIdStaffChange = (e: { value: any }) => {
        setInputValIdStaff(e.value);
    }
    const onChangeSwitchValidez = (e: any) => {
        const isValidez: boolean = e.value;
        setInputValValidez(isValidez);
        setInputValDias('0');
        setDisabledDias(true);
        if (isValidez) {
            setDisabledDias(false);
            setInputValDias('7');
            setTimeout(() => {
                inputDiasRef.current?.select();
            }, 100);
        }
    }
    // const onChangeSwitchPagar = (e: any) => {
    //     const swPagar: boolean = e.value;
    //     setInputValPagado(swPagar);
    //     //valor: vl_Pagado recien cambia en la renderizacion 
    //     // console.log(vl_Pagado);
    //     setInputValMontoPagado(0);
    //     setInputValIdFormaPago('');
    //     //Disable for inputs pago
    //     setDisabledMonto(true);
    //     setDisabledIdFormaPago(true);
    //     setDisabledReferenciaPago(true);
    //     setCalculaImporteSaldo(vl_TotalInversion, 0, vl_Cuota);

    //     if (swPagar) {
    //         setDisabledMonto(false);
    //         setDisabledIdFormaPago(false);
    //         setDisabledReferenciaPago(false);
    //         setInputValMontoPagado(vl_TotalInversion);
    //         setInputValIdFormaPago('CA');
    //         setTimeout(() => {
    //             // inputMontoRef.current?.focus();
    //             setCalculaImporteSaldo(vl_TotalInversion, vl_TotalInversion, vl_Cuota);
    //         }, 100);
    //     }
    // }

    // const setCalcularCuota = (ImporteSaldo: any) => {
    //     // console.log('setCalcularCuota' + ImporteSaldo );
    //     return vl_NumeroCuotas > 0 ? ImporteSaldo / vl_NumeroCuotas : 0;
    // }

    // const onChangeSwitchCuota = (e: any) => {

    //     const isCuota: boolean = e.value;
    //     setInputValCuota(isCuota);
    //     setInputValNumeroCuotas(0);
    //     setInputValImporteCuotas(0);
    //     setDisabledNumeroCuotas(true);
    //     // setInputValImporteSaldo(vl_TotalInversion);        
    //     setCalculaImporteSaldo(vl_TotalInversion, vl_MontoPagado, isCuota);

    //     if (isCuota) {
    //         setDisabledNumeroCuotas(false);
    //         setInputValNumeroCuotas(1);
    //         const vl_ImporteCuotas: number = setCalcularCuota(vl_TotalInversion);
    //         setInputValImporteCuotas(vl_ImporteCuotas);
    //         inputNumeroCuotasRef.current?.select();
    //     }
    //     // else{
    //     //     //calcular saldo
    //     //     if ((vl_TotalInversion - vl_MontoPagado) == 0){
    //     //         console.log('no hay saldo');
    //     //         setInputValNumeroCuotas(0);
    //     //         setInputValImporteCuotas(0);
    //     //         setDisabledNumeroCuotas(true);
    //     //     }
    //     // }
    // }

    // const setCalculaImporteSaldo = (inTotalInversion: any, inMontoPagado: any, inCuotas: boolean) => {
    //     // console.log( 'setCalculaImporteSaldo=>inTotalInversion - inMontoPagado' );
    //     // console.log( inTotalInversion );
    //     // console.log( inMontoPagado );  
    //     // console.log( inCuotas );  

    //     const ImporteSaldo: number = inTotalInversion - inMontoPagado;
    //     // console.log(ImporteSaldo);
    //     setInputValImporteSaldo(ImporteSaldo);
    //     //recalcula CUOTAS        
    //     if (inCuotas)
    //         setInputValImporteCuotas(setCalcularCuota(ImporteSaldo));
    // }

    const pa_get_tipo_servicio = async () => {
        const param = {
            "vp_Tipo": "A",
            "vp_estado": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataCodigoServicio(record);
        // setSource(record);
    }


    const pa_get_tipo_servicio_atencion = async () => {
        const param = {
            "VP_Tipo": "F",
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('admision/pa_get_tipo_servicio_atencion', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataTipoAtencion(record);
    }



    // const pa_get_forma_pago = async () => {
    //     // const param = {
    //     //     "vp_IdFormaPago": "",
    //     //     "vp_estado": ""
    //     // }
    //     // const response = await fetchSinToken('selectors/pa_get_forma_pago', param, 'POST');
    //     // const resp = await response.json();
    //     // const record = resp.data;
    //     // setIdDataFormaPago(record);
    // }

    const pa_get_especialidad = async () => {
        //e.preventDefault();        
        const param = {
            "vp_opcion": "S",
            "vp_IdEspecialidad": "0",
            "vp_Descripcion": "",
            "vp_Estado": "0"
        }
        const response = await fetchSinToken('selectors/pa_get_especialidad', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataEspecialidad(record);
    }

    const pa_get_staff = async () => {
        //e.preventDefault();
        const param = {
            "vp_opcion": "B",
            "vp_IdStaff": "0",
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdStaff(record);
    }

    // const pa_get_plan_pagos = async (IdPlanPago: any) => {
    //     // console.log('IdPlanPago:'+ IdPlanPago );        
    //     if (Number(IdPlanPago) > 0) {
    //         const param = {
    //             "vp_IdPlanPago": IdPlanPago
    //         }
    //         const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         setDataPlanPagos(record);
    //     }
    // }

    // const pa_get_orden_pago = async (IdRegistro: any) => {
    //     // console.log('IdPlanPago:'+ IdPlanPago );        
    //     if (Number(IdRegistro) > 0) {
    //         const param = {
    //             "VP_IdRegistro": IdRegistro,
    //             "VP_IdCliente": '0'
    //         }
    //         const response = await fetchSinToken('admision/pa_get_orden_pago', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         setDataOrdenPago(record);
    //     }
    // }

    //
    // useEffect: Se ejecuta después de cada cambio y cuando este renderizado por completado
    //
    useEffect(() => {
        // console.log(props.isMode);
        setInputValACTION(props.isMode);
        pa_get_tipo_servicio();
        pa_get_especialidad();
        pa_get_staff();
        // pa_get_forma_pago();
        pa_get_tipo_servicio_atencion();
        //control form segun mode(crud)
        setHiddenbtnGrabar(true);
        setHiddenbtnAnular(true);
        // setHiddenbtnEdit(true);
        setDisabledNumeroHistoriaClinica(false);
        switch (props.isMode) {
            case 'I': //INSERT MODE
                setTimeout(() => {
                    inputNumeroHistoriaClinicaRef.current?.focus();
                    setHiddenbtnGrabar(false);
                }, 100);
                break;
            case 'U': //VIEW MODE
                getRecorOrdenatencion();
                setDisabledNumeroHistoriaClinica(true);
                // setHiddenbtnEdit(false);
                setHiddenbtnGrabar(false);
                break;
            case 'A': //ANULAR MODE
                getRecorOrdenatencion();
                setHiddenbtnGrabar(true);
                setHiddenbtnAnular(false);
                setDisabledNumeroHistoriaClinica(true);
                setTimeout(() => {
                    inputObservacionesRef.current?.select();
                }, 100);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRecorOrdenatencion = () => {
        // console.log('getRecorOrdenatencion');
        // console.log(props.isData);
        setInputValIdRegistro(props.isData.IdRegistro);
        setInputValIdOrdenAtencion(props.isData.IdOrdenAtencion);
        setInputValNumeroHistoriaClinica(props.isData.NumHistoriaClinica);
        setInputValIdHistoriaClinica(props.isData.IdHistoriaClinica);
        // console.log( new Date(Date.parse( '2022-01-10 17:48:54' )) );    
        // console.log(new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format( props.isData.HoraAtencion ));
        // console.log(new Date(Date.parse(props.isData.FechaAtencion )));
        setInputValFechaAtencion(new Date(Date.parse(props.isData.FechaAtencion)));
        setInputValHoraAtencion(new Date(Date.parse(props.isData.FechaAtencion_2)));
        setInputValNumeroOrden(props.isData.NumeroOrden);
        setInputValIdCliente(props.isData.IdCliente);
        //Cliente 
        setInputValNombre(props.isData.Nombres);
        setInputValApPaterno(props.isData.ApPaterno);
        setInputValApMaterno(props.isData.ApMaterno);
        setInputValIdTipoDocumento(props.isData.tipo_doc);
        setInputValNumeroDocumento(props.isData.NumeroDocumento);
        setInputValEdad(props.isData.Edad);
        setInputValSexo(props.isData.Sexo);

        setInputValIdEspecialidad(props.isData.IdEspecialidad);
        setInputValIdStaff(props.isData.IdStaff);

        setInputValValidez(true);
        // setDisabledValidez(true);
        setInputValDias(props.isData.Validez);
        if (Number(props.isData.Validez) > 0) {
            setInputValValidez(true);
            // setDisabledValidez(false);            
        }
        if (Object.keys(props.isData.Tipo).length > 0) {
            setChkValEvaluacion(props.isData.Tipo[0].Evaluacion === 1 ? true : false);
            setChkValInterConsulta(props.isData.Tipo[0].InterConsulta === 1 ? true : false);
            setChkValInformePsi(props.isData.Tipo[0].InformePsicologico === 1 ? true : false);
        }

        // setInputValTotalInversion(props.isData.CostoTotal); --QUITADO

        // setInputValCuota(props.isData.PagoCuotas == 1 ? true : false);
        // setInputValNumeroCuotas(props.isData.NumeroCuotas);
        // setInputValImporteCuotas(props.isData.ImporteCuotas);
        // setInputValImporteSaldo(props.isData.SaldoInicial);


        // setInputValPagado(false);
        // setDisabledMonto(true);
        // setDisabledIdFormaPago(true);
        // setDisabledReferenciaPago(true);
        // if (props.isData.PagoInicial > 0) {
        //     setInputValPagado(true);
        //     setDisabledMonto(false);
        //     setDisabledIdFormaPago(false);
        //     setDisabledReferenciaPago(false);
        // }
        //pagos  
        // setInputValMontoPagado(props.isData.PagoInicial);
        // setInputValIdFormaPago(props.isData.IdFormaPago);
        // setInputValReferenciaPago(props.isData.ReferenciaPago);

        setInputValObservaciones(props.isData.Observaciones);
        setTarget(props.isData.Procedimientos);
        setCalculaTotalInversion_view_mode(props.isData.Procedimientos);
        //news
        setNumeroSesiones(props.isData.NumeroSesiones);
        setFrecuenciaAtencion(props.isData.FrecuenciaAtencion);
        setIdTipoAtencion(props.isData.IdTipoAtencion);

        //  pa_get_orden_pago(props.isData.IdRegistro);

    }


    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        if (vl_IdCliente === "0" || String(vl_IdCliente).trim() === "") {
            vl_valida = false;
            vl_message = 'Seleccionar un Cliente';
        } else if (parseFloat(vl_NumeroSesiones) <= 0) {
            vl_valida = false;
            vl_message = 'Nº sesiones debe ser mayor a 0';
        } else if (String(IdTipoAtencion).trim() === "") {
            vl_valida = false;
            vl_message = 'Seleccionar Tipo Atención';
        } else if (vl_Validez) {
            if (parseFloat(vl_Dias) === 0) {
                vl_valida = false;
                vl_message = 'Ingresar validez de dias mayor a 0 ';
            }
        } else if (parseFloat(vl_CantidadTotalSel) === 0) {
            vl_valida = false;
            vl_message = 'Debe agregar detalle';
        }

        return [vl_valida, vl_message];
    }

    const handleCloseShowConfirmSave = () => {
        setVisibleConfirmDialog(false);
    }

    const handleCloseShowConfirmAnular = () => {
        setVisibleConfirmDialog1(false);
    }

    const setBuscarVoid = () => {
        console.log('setBuscarVoid');
    }
    const setIsMountedFormDeriv = (val: boolean) => {
        isMountedFormDeriv.current = val;
    }

    const DerivacionPacienteFormNew = () => {
        return (
            <DerivacionPacienteForm
                isOpen={isOpenModal01}
                isHide={closeModal01}
                isData={dataCliente}
                isMode='I'
                setBuscar={setBuscarVoid}
                setIsMounted={setIsMountedFormDeriv}
            />
        );
    }

    const handleOnSubmitOrdenAtencion = async () => {

        switch (vl_ACTION) {
            case 'I': case 'U':
                setVisibleConfirmDialog(false);
                const IsValidaCampos = onValidarCampos();
                const Isvalido = IsValidaCampos[0];
                const IsMessage = IsValidaCampos[1];
                if (!Isvalido) {
                    toast.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 8000 });
                    return;
                }
                break;
            case 'A':
                setVisibleConfirmDialog1(false);

                break;
            default:
                break;
        }

        const jsonTipo: any = [];
        jsonTipo.push({
            'Evaluacion': chkValEvaluacion ? 1 : 0,
            'InterConsulta': chkValInterConsulta ? 1 : 0,
            'InformePsicologico': chkValInformePsi ? 1 : 0
        });

        // console.log(vl_FechaRegistro);
        // console.log(setIntlDateTimeFormat(vl_FechaRegistro));
        // console.log(setIntlTimeFormat(vl_FechaRegistro));
        // return;   

        const param = {
            VP_ACTION: vl_ACTION,
            VP_IdRegistro: vl_IdRegistro,
            VP_IdCliente: vl_IdCliente,
            VP_IdHistoriaClinica: vl_IdHistoriaClinica,
            VP_NumeroHistoriaClinica: vl_NumeroHistoriaClinica,
            VP_FechaAtencion: setIntlDateTimeFormat(vl_FechaAtencion),
            VP_HoraAtencion: setIntlTimeFormat(vl_FechaAtencion),
            VP_TipoRegistro: 'A',
            // VP_Total: vl_TotalInversion,
            // VP_Pagos: vl_MontoPagado,
            // VP_Saldo: vl_ImporteSaldo,
            // VP_Pagado: vl_Pagado ? '1' : '0',
            // VP_EstadoPago: vl_ImporteSaldo == 0 ? 'T' : 'P',
            VP_Observaciones: vl_Observaciones,
            VP_EstadoRegistro: '',
            VP_FrecuenciaAtencion: vl_FrecuenciaAtencion,
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdOrdenAtencion: vl_IdOrdenAtencion,
            VP_IdStaff: vl_IdStaff,
            VP_IdEspecialidad: vl_IdEspecialidad,
            VP_Tipo: JSON.stringify(jsonTipo),
            VP_Procedimientos: JSON.stringify(target),
            VP_Validez: vl_Dias,
            VP_NumeroSesiones: vl_NumeroSesiones,
            VP_IdTipoAtencion: IdTipoAtencion
            // VP_MontoPagado: vl_MontoPagado,
            // VP_IdFormaPago: vl_IdFormaPago,
            // VP_ReferenciaPago: vl_ReferenciaPago,
            // newa
            // VP_TipoPago: vl_TipoPago,
            // VP_PagoCuotas: vl_Cuota ? 1 : 0,
            // VP_NumeroCuotas: vl_NumeroCuotas,
            // VP_ImporteCuota: vl_ImporteCuotas
        };

        // console.log(param);
        // return;

        const response = await fetchSinToken('admision/pa_set_registro_orden_atencion', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        if (record) {
            //console.log(record);
            const message = record.sql_message;
            toast.current.show({ severity: 'info', summary: 'Message', detail: message, life: 10000 });
            //eventos de la pantalla anterior
            if (record.sql_error === 0) {
                props.setIsMounted(false);
                props.isHide();
                props.setBuscar();
                props.setSelectedGrid1([]);
            }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
        // console.log(resp)
    }

    //initializa valores de input 
    const setInitInputValCliente = () => {

        switch (vl_ACTION) {
            case 'I':
                break;
            case 'V':
                break;
            case 'A':
                break;
            default:
                break;
        }

        // setDisabledNumeroDocumento(false);

        // setInputValIdCliente('0');
        // setInputValNumeroDocumento('');
        // setInputValIdTipoDocumento('1');
        // // setInputValMinoriaEdad(false);
        // setInputValNombre('');
        // setInputValApPaterno('');
        // setInputValApMaterno('');
        // setInputValFechaNacimiento('');
        // setInputValEdad(0);
        // setInputValSexo('');
        // setInputValDireccionEmail('');
        // setInputValNumeroTelefono('');

    }

    // buscar CLIENTE x numero de documento
    const getBuscarCliente = async (e: any) => {
        e.preventDefault();
        const param = {
            "VP_IdCliente": 0,
            "VP_NumeroDocumento": "",
            "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        setInitInputValCliente();

        if (record) {
            setDataCliente(record);
            //si existe cliente dehabilitar datos del cliente      
            // setDisabledNumeroDocumento(true);
            setInputValIdCliente(record.IdCliente);
            setInputValNombre(record.Nombres);
            setInputValApPaterno(record.ApPaterno);
            setInputValApMaterno(record.ApMaterno);
            setInputValEdad(record.Edad);
            setInputValNumeroDocumento(record.NumeroDocumento);
            setInputValIdTipoDocumento(record.tipo_doc);
            setInputValSexo(record.Sexo);
            setInputValIdHistoriaClinica(record.IdHistoriaClinica);
        } else {
            const message = 'No se encontro cliente';
            toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: message, life: 10000 });
            inputNumeroHistoriaClinicaRef.current.focus();
            inputNumeroHistoriaClinicaRef.current.select();
        }
    }
    const getBuscarCliente2 = async (IN_NumeroHistoriaClinica: any) => {
        //e.preventDefault();
        const param = {
            "VP_IdCliente": 0,
            "VP_NumeroDocumento": "",
            "VP_NumeroHistoriaClinica": IN_NumeroHistoriaClinica,
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        setInitInputValCliente();
        if (record) {
            setDataCliente(record);
            //si existe cliente dehabilitar datos del cliente      
            // setDisabledNumeroDocumento(true);
            setInputValIdCliente(record.IdCliente);
            setInputValNombre(record.Nombres);
            setInputValApPaterno(record.ApPaterno);
            setInputValApMaterno(record.ApMaterno);
            setInputValEdad(record.Edad);
            setInputValNumeroDocumento(record.NumeroDocumento);
            setInputValIdTipoDocumento(record.tipo_doc);
            setInputValSexo(record.Sexo);
            setInputValIdHistoriaClinica(record.IdHistoriaClinica);
            setInputValNumeroHistoriaClinica(record.NumeroHistoriaClinica); //new
        } else {
            const message = 'No se encontro cliente';
            toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: message, life: 10000 });
            inputNumeroHistoriaClinicaRef.current.focus();
            inputNumeroHistoriaClinicaRef.current.select();
        }
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="Grabar" hidden={b_hiddenGrabar} icon="pi pi-save" onClick={() => setVisibleConfirmDialog(true)} />                
                <Button label="Imprimir" className="p-button-text" icon="pi pi-print" onClick={() => setReportOA()} />
                <Button label="Anular" hidden={b_hiddenAnular} icon="pi pi-ban" onClick={() => setVisibleConfirmDialog1(true)} />
                <Button label="Cerrar" icon="pi pi-times"  onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} className="p-button-rounded p-button-danger  ml-2" />                
            </>
        );
    }

    const setReportOA = async () => {
        // setReportView(true);         
        // const config = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
        const endpoint = 'reports/ordenAtencion/?IdOrdenAtencion=' + vl_IdOrdenAtencion;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    const setCalculaTotalInversion = (p_target: any) => {
        setInputValCantidadTotalSel(p_target.length);
    }
    const setCalculaTotalInversion_view_mode = (p_target: any) => {
        setInputValCantidadTotalSel(p_target.length);
    }

    // PARA EDITAR CELDAS
    // const priceEditor = (options: any) => {
    //     return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />
    // }

    const textEditor = (options: any) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    // const priceBodyTemplate = (rowData: any) => {
    //     return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.Importe);
    // }
    const isPositiveInteger = (val: any) => {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        let n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    const onCellEditComplete = (e: any) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        // console.log(rowData.Importe +'--'+ newValue);
        switch (field) {
            case 'Cantidad': // no usado aqui
                if (isPositiveInteger(newValue))
                    rowData[field] = newValue;
                else
                    event.preventDefault();
                // recalcular totales si hay variacion del precio            
                setCalculaTotalInversion(target);
                break;
            case 'Importe':
                rowData[field] = newValue;
                // event.preventDefault();
                // recalcular totales si hay variacion del precio        
                setCalculaTotalInversion(target);
                break;
            default:
                if (newValue.trim().length > 0)
                    rowData[field] = newValue;
                else
                    event.preventDefault();
                break;
        }
    }


    const gridDeleteItem = (item: any) => {
        let itemsfiltered = target.filter(function (element: any) {
            // console.log(element);
            return element.CodigoServicio ? element.CodigoServicio !== item.CodigoServicio : [];
        });
        setTarget(itemsfiltered);
        //close DIALOG CONFIRM
        setDeleteItemDialog(false);
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash"
                    tooltip="Quitar item"
                    className="p-button-small p-button-danger" onClick={() => confirmDeleteItem(rowData)} />
            </React.Fragment>
        );
    }
    const confirmDeleteItem = (rowData: any) => {
        setEditingRows(rowData);
        setDeleteItemDialog(true);
    }

    const deleteItemDialogFooter = () => {
        // console.log( editingRows );
        return (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteItemDialog(false)} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={() => gridDeleteItem(editingRows)} />
            </React.Fragment>
        );
    }

    const handleClickFormDerivacion = (e: any) => {
        // setMode('I');        
        if (dataCliente.length === 0) {
            toast.current.show({ severity: 'warn', summary: 'Message', detail: 'Debe seleccionar un paciente', life: 10000 });
            return;
        }
        isMountedFormDeriv.current = true;
        openModal01();
    }
    const footer = () => {
        // let vl_Total: any = 0;
        // admision.forEach(
        //   function (currentValue: any) {
        //     vl_Total = vl_Total + parseFloat(currentValue.CostoTotal);
        //   }
        // );
        return (
            <>
                <FooterGridQuantity Total={vl_CantidadTotalSel} />
            </>
        );
    }

    // new buscador clientes
    const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
    const isMounted = useRef(false);
    const handleClickBuscar = (e: any) => {
        isMounted.current = true;
        openModal1();
    }
    const setIsMounted2 = (val: boolean) => {
        isMounted.current = val;
    }
    const BuscadorClientesForm = () => {
        return (
            <BuscadorClientes
                isOpen={isOpenModal1}
                isHide={closeModal1}
                getDataCliente={getDataCliente}
                setIsMounted={setIsMounted2}
            />
        );
    }
    const getDataCliente = (data: any) => {
        // console.log(data.NumeroDocumento);    
        getBuscarCliente2(data.NumeroHistoriaClinica);
    }
    const renderHeader = () => {
        return (
            <>
                <TitleForm title={vl_NumeroOrden ? 'Orden de Atenciónxx Nº: ' + vl_NumeroOrden : 'Orden de Atención'} />
            </>
        );
    }


    // const [filters2, setFilters2] = useState({
    //     'Descripcion': { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     'name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    //     'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    //     'representative': { value: null, matchMode: FilterMatchMode.IN },
    //     'status': { value: null, matchMode: FilterMatchMode.EQUALS },
    //     'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
    // });


    return (
        <Dialog
            header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
            visible={props.isOpen}
            footer={renderFooter}
            onHide={() => {
                props.setIsMounted(false);
                props.isHide();
            }}
        >
            <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
            <Filter
                IdHistoriaClinica={vl_IdHistoriaClinica}
                NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
                disabledNumeroHistoriaClinica={disabledNumeroHistoriaClinica}
                inputNumeroHistoriaClinicaRef={inputNumeroHistoriaClinicaRef}
                setInputValNumeroHistoriaClinica={setInputValNumeroHistoriaClinica}
                getBuscarCliente={getBuscarCliente}
                handleClickBuscar={handleClickBuscar}
                IdOrdenAtencion={vl_IdOrdenAtencion}
                NumeroOrden={vl_NumeroOrden}
                FechaAtencion={vl_FechaAtencion}
                setInputValFechaAtencion={setInputValFechaAtencion}
                HoraAtencion={vl_HoraAtencion}
                setInputValHoraAtencion={setInputValHoraAtencion}
                IdRegistro={vl_IdRegistro}
            />

            <DatoPaciente
                IdCliente={vl_IdCliente}
                Nombre={vl_Nombre}
                ApPaterno={vl_ApPaterno}
                ApMaterno={vl_ApMaterno}
                IdTipoDocumento={vl_IdTipoDocumento}
                NumeroDocumento={vl_NumeroDocumento}
                NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
                IdHistoriaClinica={vl_IdHistoriaClinica}
                Edad={vl_Edad}
                Sexo={vl_Sexo}
            />

            <TabView className="tabview-custom"
                activeIndex={activeIndex}
                onTabChange={(e: any) => {
                    setActiveIndex(e.index);
                    switch (e.index) {
                        case 0: break;
                        case 1:
                            // if (vl_ACTION !== 'V')
                            //     setCalculaImporteSaldo(vl_TotalInversion, vl_MontoPagado, vl_Cuota);
                            break;
                        default: break;
                    }
                }}
            >
                <TabPanel header="Procedimientos" leftIcon="pi pi-th-large" >
                    <div className="p-field p-grid mb-1">
                        <div className="p-col">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Especialidad:</label>
                            <Dropdown style={{ height: '35px', width: '250px' }} filter filterBy="Descripcion"
                                value={vl_IdEspecialidad}
                                options={dataEspecialidad}
                                onChange={onIdEspecialidadChange}
                                optionValue="IdEspecialidad"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />

                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ height: '35px', width: '250px' }} filter filterBy="Descripcion"
                                value={vl_IdStaff}
                                options={dataIdStaff}
                                onChange={onIdStaffChange}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Seleccionar" />
                        </div>

                        <div className="p-col mt-1">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Válidez</label>
                            <InputSwitch checked={vl_Validez} style={{ height: '28px' }}
                                //tooltip='Especifique la validez de la orden en dias'
                                onChange={(e: any) => onChangeSwitchValidez(e)}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold" style={{ width: '70px', textAlign: 'end' }}>Dias:</label>
                            <InputText type="number" style={{ height: '30px', width: '60px', textAlign: 'center' }}
                                value={vl_Dias}
                                disabled={disabledDias}
                                ref={inputDiasRef}
                                onChange={(e) => setInputValDias(String(e.currentTarget.value))}
                            />

                            <Checkbox className="ml-3" inputId="cb1"
                                onChange={e => setChkValEvaluacion(e.checked)}
                                checked={chkValEvaluacion}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Evaluación</label>
                            <Checkbox className="ml-3" inputId="cb1"
                                onChange={(e) => {
                                    setChkValInterConsulta(e.checked)
                                    if (e.checked)
                                        handleClickFormDerivacion(e);
                                }
                                }
                                checked={chkValInterConsulta}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Interconsulta</label>
                            <Checkbox className="ml-3" inputId="cb1"
                                onChange={e => setChkValInformePsi(e.checked)}
                                checked={chkValInformePsi}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Informe psicológico</label>
                        </div>
                        <div className="p-col mt-1">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold" style={{ width: '120px', textAlign: 'end' }}>Nº sesiones:</label>
                            <InputText type="number" style={{ height: '30px', width: '60px', textAlign: 'center' }}
                                value={vl_NumeroSesiones}
                                // disabled={disabledDias}
                                // ref={inputDiasRef}
                                onChange={(e) => setNumeroSesiones(String(e.currentTarget.value))}
                            />

                            <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold" style={{ textAlign: 'end' }}>Frecuencia atención</label>
                            <InputText type="text" style={{ height: '30px', width: '50%', textAlign: 'left' }}
                                placeholder="Semanal, Mensual, etc"
                                value={vl_FrecuenciaAtencion}
                                // disabled={disabledDias}
                                // ref={inputDiasRef}
                                onChange={(e) => setFrecuenciaAtencion(String(e.currentTarget.value))}
                            />
                        </div>
                        <div className="p-col mt-1">
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Tipo Atención:</label>
                            <Dropdown style={{ height: '35px', width: '350px' }}
                                // filter filterBy="Descripcion"
                                value={IdTipoAtencion}
                                options={dataTipoAtencion}
                                onChange={(e: any) => {
                                    setIdTipoAtencion(e.value);
                                }}
                                optionValue="CodigoServicio"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />
                            <label className="pr-5" style={{ textAlign: 'end' }}> </label>
                            <Button
                                type="button"
                                icon="pi pi-plus"
                                style={{ height: '32px' }}
                                label="Agregar"
                                onClick={(e) => op.current?.toggle(e)}
                                aria-haspopup
                                aria-controls="overlay_panel"
                                className="p-button-small p-button-success pl-1 pr-2"
                            />

                        </div>
                        <div className="p-col pt-1 ">
                            <DataTable size="small"
                                resizableColumns
                                // footer={footer}
                                style={{ height: '210px' }}
                                value={target ?? []}  //props.isData.Procedimientos
                                dataKey="CodigoServicio"
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                scrollHeight="220px"
                                selectionMode="single"
                                editMode="cell"
                            // selection={editingRows}
                            // onSelectionChange={e => setEditingRows(e.value)}
                            >
                                <Column field="CodigoServicio" header="" body={actionBodyTemplate} style={{ maxWidth: '64px' }}  ></Column>
                                <Column field="Descripcion" header="Detalle servicio"></Column>
                                {/* <Column field="Importe" header="Costo" style={{ maxWidth: '100px' }} body={priceBodyTemplate}
                                editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete}
                                headerClassName="flex justify-content-end"
                                bodyClassName="flex justify-content-end text-green-800 font-bold"
                                ></Column> */}
                                <Column field="Nota" header="Comentario"
                                    editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                            </DataTable>
                        </div>

                    </div>
                </TabPanel>

            </TabView>

            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{ width: "450px" }}>

                <DataTable
                    size="small"
                    resizableColumns
                    value={dataCodigoServicios ?? []}
                    dataKey="CodigoServicio"
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"
                    paginator
                    rows={10}
                    selection={selectedIemsGrid}
                    // filters={filters2} filterDisplay="row"
                    onSelectionChange={(e) => {
                        setSelectedIemsGrid(e.value);
                        let targetModel = {
                            UnidadMedida: 'UNI',
                            Cantidad: 1,
                            CodigoServicio: e.value.CodigoServicio,
                            Descripcion: e.value.Descripcion,
                            Importe: e.value.Costo,
                            Descuento: 0,
                            Impuesto: 0,
                            Total: e.value.Costo,
                            Nota: ''
                        };
                        target.push(targetModel);
                        setCalculaTotalInversion(target);
                    }
                    }
                >
                    <Column selectionMode="single" style={{ maxWidth: '50px' }} ></Column>
                    <Column header="Catálago Atención" field="Descripcion" filter></Column>
                </DataTable>

                <Button className="ml-1 mt-1"
                    type="button"
                    icon="pi pi-check"
                    label="Aceptar"
                    onClick={() => {
                        op.current?.hide()
                    }}
                />
            </OverlayPanel>


            <Toast ref={toast} position="top-center"></Toast>
            <Toast ref={toastConfirm} position="center"></Toast>

            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message="¿Guardar Registro?"
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={handleOnSubmitOrdenAtencion}
                reject={handleCloseShowConfirmSave}
            />

            <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
                message="Anular Registro?"
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={handleOnSubmitOrdenAtencion}
                reject={handleCloseShowConfirmAnular}
            />

            <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirmar" modal
                footer={deleteItemDialogFooter}
                onHide={() => setDeleteItemDialog(false)} >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span> ¿Estás seguro(a) de eliminar?</span>
                </div>
            </Dialog>

            <React.Fragment>{
                isMountedFormDeriv.current && <DerivacionPacienteFormNew />
            }
            </React.Fragment>

        </Dialog>
    )
};