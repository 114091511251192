import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { fetchConToken } from '../../../helpers/fetch';
import ProgressBarUtil from '../util/ProgressBarUtil';

const AuthSendResetPassword = () => {

    const [vl_username, setUsername] = useState<any>('');
    const [b_hidden1, setHidden1] = useState<boolean>(false);
    const [b_hidden2, setHidden2] = useState<boolean>(true);
    const [vl_resp, setResp] = useState<any>('');
    const [b_ProgressBar, setProgressBar] = useState<boolean>(false);
    const vl_toast_ref = useRef<any>(null);

    const handleEnviar = async (e: any) => {
        e.preventDefault();
        const param = {
            vp_UserName: vl_username
        }
        if (String(vl_username).trim() === '') {
            const VL_message = 'Ingresar el usuario';
            vl_toast_ref.current.show({ severity: 'error', summary: '::PSISABE', detail: VL_message, life: 8000 });
            return;
        }
        setProgressBar(true);
        const response = await fetchConToken('auth/send_reset_instructions', param, 'POST');
        const datajson = await response.json();
        // console.log(datajson);
        setProgressBar(false);

        if (datajson.resp) {

            // vl_toast_ref.current.show({ severity: 'success', summary: '::PSISABE', detail: datajson.data, life: 9000 });

            setHidden1(true);
            setHidden2(false);
            setResp(datajson.data)
            //redirect
            // const IdUsuario = datajson.data[0].AuhtUserData.IdUsuario;
            //     const path = '/account/AuthSuccessPage/';
            //     navigate(path);
        } else {
            vl_toast_ref.current.show({ severity: 'error', summary: '::PSISABE', detail: datajson.data, life: 8000 });
        }
    }

    const handleVerificar = async (e: any) => {
        e.preventDefault();
        const sha1 = require('js-sha1');
        const param = {
            
        }
       
        const response = await fetchConToken('auth/send_verificar_propiedad_sendGrid', param, 'POST');
        const resp = await response.json();
        console.log(resp);
    }

    return (
        <>
            <ProgressBarUtil
                ProgressBarVisible={b_ProgressBar}
                ProgressBarMessage='Please wait, loading...'
                setProgressBar={setProgressBar}
            />
            <Toast ref={vl_toast_ref} position="top-center"></Toast>
            <div className="wrapper d-flex flex-column min-vh-100 bg-gradient-indigo text-white">
                <div className='flex flex-wrap justify-content-center pb-3 m-2 ' >
                    <div className="flex justify-content-between ">
                        <div>
                            <nav className="flex justify-content-center p-4">
                                <img src="../../apple-touch-icon.png" alt="" height={'164px'} />
                            </nav>
                        </div>
                    </div>
                </div>
                <main className="form-passwor">
                    <form onSubmit={handleVerificar} >
                        <div className={b_hidden1 ? 'hidden' : ''} >
                            <div className='flex flex-column justify-content-center p-1 m-2'>
                                <div className="field">
                                    <label className='font-base text-xl text-pink-500 flex justify-content-center' >Reset Password</label>
                                </div>
                                <div className="field">
                                    <label htmlFor="username">To reset your password, please provide your psisabe app username.</label>
                                    <InputText className='p-3 mt-2' style={{ width: "100%" }} placeholder='Enter your username'
                                        value={vl_username}
                                        onChange={(e: any) => {
                                            setUsername(String(e.currentTarget.value).toUpperCase())
                                        }}
                                    >
                                    </InputText>
                                </div>
                                <div className="field flex justify-content-center ">
                                    <button className="w-70 btn btn-lg btn-primary" type="submit">Send Reset Instructions</button>
                                </div>
                            </div>

                        </div>
                    </form>
                    <div className={b_hidden2 ? 'hidden' : ''} >                   
                        <div className='flex flex-column justify-content-center p-1 m-2'>
                            <div className="field">
                                <label className='font-base text-xl text-green-600 flex justify-content-center' >Instructions sent successfully!</label>
                            </div>
                            <div className='font-base text-normal p-2 white-space-normal' >
                                {vl_resp}
                            </div>
                            {/* <div className="field flex justify-content-start ">
                                <button className="w-70 btn btn-lg btn-link text-pink-400"
                                    onClick={() => {
                                        window.close();
                                    }}
                                >Cerrar.</button>
                            </div> */}
                        </div>
                    </div>
                </main>

            </div>
        </>
    )
}

export default AuthSendResetPassword;